























































































import { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';

import BaseDialog from '@/components/BaseDialog.vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import ImageUploader from '@/components/ImageUploader.vue';

import {
  createScheduleStream,
  updateScheduleStream
} from '@/api/CopyStake/Schedule';
import { getStreamersList } from '@/api/CopyStake/Streamers';
import {
  IStreamerListResponse,
  TScheduleStream,
  TStreamer,
  ValidationRules
} from '@/api/schema';

import { errorToastMessage } from '@/helpers/errorToastMessage';
import { objectToBlob } from '@/helpers/blobHelper';
import * as vr from '@/helpers/validation';

const maxFileSize = 1024 * 400;
const imageThreeFourRequirements = `Image aspect ratio must be 3:4 (recommended dimensions is 810 x 1080 pixels) in JPEG, JPG or WEBP (file size – up to 400kb)`;
const imageSixteenNineRequirements = `Image aspect ratio must be 16:9 (recommended dimensions is 1920 x 1080 pixels) in JPEG, JPG or WEBP (file size – up to 400kb)`;

export default {
  name: 'CopyStakeStreamingScheduleDialog',
  components: {
    BaseDialog,
    ImageUploader,
    VExtendedDataPicker
  },
  props: {
    value: {
      type: Boolean,
      default: (): boolean => false
    },
    scheduleStream: {
      type: Object as PropType<TScheduleStream>,
      default: (): any => ({})
    }
  },
  data(): any {
    return {
      formData: {
        streamerId: '',
        dateOfStream: ''
      },
      loading: {
        loadStreamers: false,
        upload: false
      },
      uploadImageThreeFourFile: null,
      uploadImageSixteenNineFile: null,
      imageThreeFourRequirements,
      imageSixteenNineRequirements,
      maxFileSize,
      isFormValid: true,
      streamersList: null,
      fileInputKey: 1
    };
  },
  watch: {
    async value(): Promise<void> {
      await this.resetForm();

      if (this.scheduleStream) {
        this.formData = {
          streamerId: this.scheduleStream.streamerId ?? '',
          dateOfStream: (this.scheduleStream.dateOfStream ?? '').replace(
            /:00$/,
            ''
          )
        };
      }

      if (!this.streamersList) {
        this.getStreamersData();
      }
    }
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    rules(): ValidationRules {
      return {
        streamerId: [vr.required],
        dateOfStream: [vr.required],
        imageThreeFour: [
          vr.required,
          (v) => vr.fileMaxSize(v, maxFileSize, imageThreeFourRequirements)
        ],
        imageSixteenNine: [
          vr.required,
          (v) => vr.fileMaxSize(v, maxFileSize, imageSixteenNineRequirements)
        ]
      };
    },
    showDialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    isEdit(): boolean {
      return !!this.scheduleStream?.id;
    },
    submitText(): string {
      return this.isEdit ? 'Update' : 'Create';
    },
    imageThreeFourUrl(): string {
      return this.scheduleStream?.images?.THREE_FOUR ?? '';
    },
    imageSixteenNineUrl(): string {
      return this.scheduleStream?.images?.SIXTEEN_NINE ?? '';
    }
  },
  methods: {
    getStreamersData(): void {
      if (!this.operatorId) return;

      this.loading.loadStreamers = true;

      getStreamersList({
        size: 1000,
        page: 0,
        operatorId: this.operatorId
      })
        .then((data: IStreamerListResponse) => {
          this.streamersList = data.content.map(
            ({ nickname, id }: TStreamer) => ({
              text: nickname,
              value: id
            })
          );
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally(() => {
          this.loading.loadStreamers = false;
        });
    },
    inputFileHandler(file: Blob): void {
      this.uploadImageFile = file;
    },
    async submitHandler(): Promise<void> {
      try {
        await this.$refs.form.validate();

        if (!this.isFormValid) return;

        this.loading.upload = true;

        const dateOfStream = DateTime.fromJSDate(
          new Date(this.formData.dateOfStream)
        );
        const formData = new FormData();

        formData.append(
          'scheduleData',
          objectToBlob({
            streamerId: this.formData.streamerId,
            dateOfStream: dateOfStream.toFormat('yyyy-MM-dd HH:mm:ss')
          })
        );

        if (this.uploadImageThreeFourFile) {
          formData.append('imageThreeFour', this.uploadImageThreeFourFile);
        }

        if (this.uploadImageSixteenNineFile) {
          formData.append(
            'imageSixteenNine',
            this.uploadImageSixteenNineFile
          );
        }

        this.isEdit
          ? await updateScheduleStream(this.scheduleStream.id, formData)
          : await createScheduleStream(formData);

        this.$toast.success('Stream has been successfully scheduled');

        this.close();
        this.$emit('submit');
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.loading.upload = false;
      }
    },
    handlerOnUploadImageThreeFour(image: File): void {
      this.uploadImageThreeFourFile = image;
    },
    handlerOnUploadImageSixteenNine(image: File): void {
      this.uploadImageSixteenNineFile = image;
    },
    close(): void {
      this.showDialog = false;
      this.$emit('close');
    },
    async resetForm(): Promise<void> {
      await this.$refs.form?.reset();

      this.$refs.imageThreeFourUploader?.clearConfiguration();
      this.$refs.imageSixteenNineUploader?.clearConfiguration();
      this.uploadImageThreeFourFile = null;
      this.uploadImageSixteenNineFile = null;

      this.formData = {
        streamerId: '',
        dateOfStream: ''
      };
    }
  }
};
